import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { useWindowSize } from '../hooks/useWindowSize';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { media } from '../utils/media-queries';
import RichText from '../components/richtext';
import { useEventListener } from '../hooks/useEventListener';
import { useClientSide } from '../hooks/useClientSide';

const ArtProject = ({ data }) => {
  const { title, content } = data.artProject;
  const { backgroundColor } = data.artPage;

  const size = useWindowSize();
  const isMobile = size.width < 768;

  const ref = useRef();
  const isClient = useClientSide();

  const keyScroll = e => {
    if (ref.current === null) return;

    const speed = 10;
    if (e.keyCode === 40 || e.keyCode === 39) ref.current.scrollLeft += speed;
    if (e.keyCode === 38 || e.keyCode === 37) ref.current.scrollLeft -= speed;
  };

  useEventListener('keydown', keyScroll, isClient && document);

  return (
    <Layout color={backgroundColor.hex}>
      <SEO siteTitle="kunst" />
      <StyledWrapper ref={ref}>
        <StyledTitle className="headline">{title}</StyledTitle>
        <StyledContent className="grid">
          {content.map((item, i) =>
            item.__typename === 'SanityArtImage' ? (
              <div
                key={i}
                style={{
                  gridColumn: item.position && !isMobile ? '1 / 3' : '',
                }}
                className="img-wrapper"
              >
                <Img
                  className={item.portrait ? 'portrait-img' : 'img'}
                  fluid={item.image.asset.fluid}
                  alt={item.alt}
                />
              </div>
            ) : (
              <div
                key={i}
                className="text"
                style={{
                  gridColumn: item.position && !isMobile ? '1 / 3' : '',
                }}
              >
                <RichText blocks={item._rawText} />
              </div>
            )
          )}
        </StyledContent>
      </StyledWrapper>
    </Layout>
  );
};

const StyledWrapper = styled.section`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  padding-top: var(--spacing-XS);

  @media ${media.M} {
    overflow-y: scroll;
    height: 80vh;
    padding-top: 0;
    padding-right: 10vh;
  }
`;

const StyledTitle = styled.h2`
  padding: 0 var(--spacing-XXS) var(--spacing-XXS) var(--spacing-XXS);

  @media ${media.M} {
    padding: 0 0 var(--spacing-XXS) 0;
  }
`;

const StyledContent = styled.section`
  .text {
    padding: 0 var(--spacing-XXS) var(--spacing-XXS) var(--spacing-XXS);
  }

  .img-wrapper {
    padding-bottom: var(--spacing-XXS);
  }

  @media ${media.M} {
    .text {
      padding: 0 var(--spacing-XXS) var(--spacing-XXS) 0;
    }

    .img {
      flex: 1;
      min-width: 100%;
      height: 100%;
    }

    .portrait-img {
      flex: 1;
      min-width: 45%;
      height: 100%;
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    artProject: sanityArtProject(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      content {
        ... on SanityArtImage {
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          portrait
          position
        }
        ... on SanityArtText {
          _rawText
          position
        }
      }
    }
    artPage: sanityArtPage {
      backgroundColor {
        hex
      }
    }
  }
`;

ArtProject.propTypes = {
  data: PropTypes.object,
};

export default ArtProject;
